
import { AuthPageWrapper, SignUpWrapper } from "~/auth/index.styles"
import { AuthSignupLink, AuthSignupMessage, AuthTitle } from "~/styles/Global.forms"
import SEO from "~/components/SEO/SEO"
import { Divider } from "semantic-ui-react"
import SocialLoginButtons from "~/auth/SocialLoginButtons"
import SignIn from "~/auth/Signin"

export const handle = { layout: "blank" };

const LoginPage = () => (
  <>
    <SEO title="Login" />
    <AuthPageWrapper>
        <SignUpWrapper>
            <AuthTitle>Login</AuthTitle>
            <SignIn />
            <Divider horizontal style={{ color: "#666" }}>
              Or
            </Divider>
            <SocialLoginButtons isLogin={true}/>
            <div style={{ textAlign: "center", paddingTop: "10px" }}>
              <AuthSignupMessage>Don't have an account?</AuthSignupMessage>
              <AuthSignupLink to="/signup">Sign up now!</AuthSignupLink>
            </div>
        </SignUpWrapper>
    </AuthPageWrapper>
  </>
)

export default LoginPage
